import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { ENVIRONMENT } from '@my-tomorrows/core-environment';
import { httpErrorInterceptor } from '@my-tomorrows/core-errors';
import { translationConfig, translationInitializer } from '@my-tomorrows/core-translation';
import { getMsalProviders, msalAuthInterceptor } from '@my-tomorrows/msal';
import { authInterceptor } from '@my-tomorrows/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../environment/environment';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptor, httpErrorInterceptor, msalAuthInterceptor])),
    ...(environment.disableAnimations ? [provideNoopAnimations()] : [provideAnimations()]),
    { provide: ENVIRONMENT, useValue: environment },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    importProvidersFrom(TranslateModule.forRoot(translationConfig)),
    translationInitializer,
    ...getMsalProviders(environment),
  ],
};
