import { DOCUMENT } from '@angular/common';
import { effect, inject, Injectable, NgZone, OnDestroy, signal, untracked } from '@angular/core';
import { Router } from '@angular/router';
import { MYT_TOKEN_RENEWAL_TIME, SharedAuthService, StorageService, UserType } from '@my-tomorrows/shared-data';
import { fromEvent, merge, sampleTime, Subscription, take, timer } from 'rxjs';

const TOKEN_EXPIRE_TIMEOUT = 20 * 60; // 20 minutes (since the token expires in 20 minutes on backend)
const MAX_USER_INACTIVITY_TIME = 30 * 60; // 30 minutes to logout after user inactivity
const INTERVAL_TO_COLLECT_USER_ACTIVITY = 10; // seconds
const INTERVAL_TO_CHECK_SESSION = 10; // seconds
const TIME_BETWEEN_RENEWAL_AND_TIMEOUT = 60; // seconds

@Injectable()
export class SessionManagerService implements OnDestroy {
  private readonly authService = inject(SharedAuthService);
  private readonly document = inject(DOCUMENT);
  private readonly storageService = inject(StorageService);
  private readonly ngZone = inject(NgZone);
  private readonly router = inject(Router);

  private sessionInterval$: Subscription;
  private userActivitySubscription$: Subscription;
  private lastUserActiveTimestamp = signal<Date>(new Date());
  private ssoUserTypes = [UserType.mytUser, UserType.siteManager];

  readonly initializer = effect(() => {
    const isUserLoggedIn = this.authService.$getUserType() !== UserType.search;
    const isSsoUser = this.ssoUserTypes.includes(this.authService.$getUserType());
    untracked(() => {
      isUserLoggedIn && !isSsoUser ? this.activateSessionManager() : this.cleanListeners();
    });
  });

  private get lastTokenRenewalTime(): number | Date {
    return this.storageService.getItem(MYT_TOKEN_RENEWAL_TIME) || new Date();
  }

  private get isUserStillActive(): boolean {
    const minutesAfterUserActivity = this.getTimeDifferenceInSeconds(this.lastUserActiveTimestamp(), new Date());
    return minutesAfterUserActivity < MAX_USER_INACTIVITY_TIME;
  }

  private get isTokenAboutToExpire(): boolean {
    const minutesAfterLastTokenRenewal = this.getTimeDifferenceInSeconds(new Date(this.lastTokenRenewalTime), new Date());
    return TOKEN_EXPIRE_TIMEOUT - minutesAfterLastTokenRenewal <= TIME_BETWEEN_RENEWAL_AND_TIMEOUT;
  }

  private get isTokenAlreadyExpired(): boolean {
    const minutesAfterLastTokenRenewal = this.getTimeDifferenceInSeconds(new Date(this.lastTokenRenewalTime), new Date());
    return minutesAfterLastTokenRenewal > TOKEN_EXPIRE_TIMEOUT;
  }

  ngOnDestroy(): void {
    this.sessionInterval$?.unsubscribe();
    this.userActivitySubscription$?.unsubscribe();
  }

  private activateSessionManager() {
    if (this.isTokenAlreadyExpired) {
      this.logout();
    } else {
      this.ngZone.runOutsideAngular(() => {
        this.listenUserActivity();
        this.startInterval();
      });
    }
  }

  private startInterval() {
    if (this.sessionInterval$) {
      this.sessionInterval$.unsubscribe();
    }

    this.sessionInterval$ = timer(0, INTERVAL_TO_CHECK_SESSION * 1000).subscribe(() => {
      if (!this.isUserStillActive || this.isTokenAlreadyExpired) {
        this.logout();
      }

      if (this.isTokenAboutToExpire && this.isUserStillActive) {
        this.refreshTheToken();
      }
    });
  }

  private listenUserActivity(): void {
    const mouseMoveEvent$ = fromEvent(this.document, 'mousemove').pipe(sampleTime(INTERVAL_TO_COLLECT_USER_ACTIVITY * 1000));
    const keydownEvent$ = fromEvent(this.document, 'keydown').pipe(sampleTime(INTERVAL_TO_COLLECT_USER_ACTIVITY * 1000));
    const mousedownEvent$ = fromEvent(this.document, 'mousedown').pipe(sampleTime(INTERVAL_TO_COLLECT_USER_ACTIVITY * 1000));

    if (this.userActivitySubscription$) {
      this.userActivitySubscription$.unsubscribe();
    }

    this.userActivitySubscription$ = merge(mouseMoveEvent$, keydownEvent$, mousedownEvent$).subscribe(() =>
      this.lastUserActiveTimestamp.set(new Date()),
    );
  }

  private cleanListeners() {
    this.sessionInterval$?.unsubscribe();
    this.userActivitySubscription$?.unsubscribe();
    this.storageService.removeItem(MYT_TOKEN_RENEWAL_TIME);
  }

  private refreshTheToken(): void {
    this.authService.fetchCurrentUser().pipe(take(1)).subscribe();
  }

  private logout() {
    this.authService.setDefaultUser();
    this.authService.logout().pipe(take(1)).subscribe();
    this.cleanListeners();
    this.router.navigate(['/home']);
  }

  private getTimeDifferenceInSeconds(date1: Date, date2: Date): number {
    const differenceInMilliseconds = Math.abs(date2.valueOf() - date1.valueOf());
    return Math.trunc(differenceInMilliseconds / 1000);
  }
}
