import posthog from 'posthog-js';
import { environment } from './environment/environment';

export function initializePosthog(): void {
  try {
    posthog.init(environment.posthogKey, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
    });
  } catch (error) {
    console.error('Error initializing PostHog:', error);
  }
}
