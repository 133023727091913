import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { Environment } from '@my-tomorrows/core-environment';

export function getMsalProviders(environment: Environment): Provider[] {
  function MSALInstanceFactory() {
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: environment.msal?.clientId,
        authority: environment.msal?.authority,
        redirectUri: environment.msal?.redirectUri,
        postLogoutRedirectUri: environment.msal?.redirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: !!window.navigator.userAgent.match(/(msie|trident)/i),
      },
    });
    msalInstance.initialize();
    return msalInstance;
  }

  function MSALGuardConfigFactory() {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [`${environment.msal.clientId}/.default`],
      },
    };
  }

  function MSALInterceptorConfigFactory() {
    return {
      interactionType: InteractionType.Redirect,
    };
  }

  return [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: PublicClientApplication,
      useExisting: MSAL_INSTANCE,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}
