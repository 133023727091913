import { DOCUMENT } from '@angular/common';
import { DestroyRef, Directive, ElementRef, NgZone, OnInit, Renderer2, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[mytToggleNavbarOnScroll]',
  standalone: true,
})
export class NavbarScrollDirective implements OnInit {
  private readonly renderer = inject(Renderer2);
  private readonly el = inject(ElementRef);
  private readonly ngZone = inject(NgZone);
  private readonly document = inject(DOCUMENT);
  private readonly destroyRef = inject(DestroyRef);

  readonly mytToggleNavbarOnScroll = input<boolean>(true);

  private lastScrollTop = 0;
  private navbarHeight = 0;
  private isNavbarHidden = false;

  ngOnInit() {
    if (this.mytToggleNavbarOnScroll()) {
      this.setNavbarHeightAndStyle();
      this.listenScroll();
    }
  }

  private listenScroll() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'scroll')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const currentScrollTop = this.document.defaultView?.scrollY || 0;
          if (currentScrollTop > this.lastScrollTop && currentScrollTop > this.navbarHeight) {
            if (!this.isNavbarHidden) {
              this.hideNavbar();
            }
          } else if (currentScrollTop < this.lastScrollTop) {
            if (this.isNavbarHidden) {
              this.showNavbar();
            }
          }

          this.lastScrollTop = currentScrollTop > 0 ? currentScrollTop : 0;
        });
    });
  }

  private setNavbarHeightAndStyle(): void {
    this.navbarHeight = this.el.nativeElement.offsetHeight;
    const style = { position: 'sticky', top: 0, left: 0, right: 0, transition: 'transform 0.3s ease-in-out', 'z-index': 10 };
    Object.entries(style).forEach(([key, value]) => {
      this.renderer.setStyle(this.el.nativeElement, key, value);
    });
  }

  private hideNavbar(): void {
    this.renderer.setStyle(this.el.nativeElement, 'transform', 'translateY(-100%)');
    this.isNavbarHidden = true;
  }

  private showNavbar(): void {
    this.renderer.setStyle(this.el.nativeElement, 'transform', 'translateY(0)');
    this.isNavbarHidden = false;
  }
}
