<div class="w-full text-neutral-500 md:flex md:justify-between">
  <div class="pb-2 md:pb-0">
    {{ 'PLATFORM.REGISTRY_DATA' | translate }}
  </div>
  <div class="">
    <span [innerHTML]="'PLATFORM.C_MY_TOMORROWS' | translate: { year }"></span>
    <a [href]="termsOfServiceLink" target="_blank" class="myt-link"> {{ 'PLATFORM.TERMS_OF_SERVICE' | translate }} </a>,
    <a [href]="privacyPolicyLink" target="_blank" class="myt-link"> {{ 'PLATFORM.PRIVACY_POLICY' | translate }} </a>
    & <a [href]="cookieStatementLink" target="_blank" class="myt-link"> {{ 'PLATFORM.COOKIE_STATEMENT' | translate }} </a>
  </div>
</div>
