<div class="platform mat-typography relative" [ngClass]="{ 'h-screen': $fullScreenHeight() }" id="scrollTarget">
  @if (!$hideHeader()) {
    <header [mytToggleNavbarOnScroll]="!$fullScreenHeight()">
      <myt-navbar />
    </header>
  }
  <main class="flex h-full flex-grow flex-col">
    @if ($breadcrumbData()) {
      <div class="platform__breadcrumb">
        <myt-breadcrumb [breadcrumbs]="$breadcrumbData()" />
      </div>
    }
    <div class="platform__content h-full overflow-x-auto">
      <router-outlet />
    </div>
  </main>

  <myt-footer />
</div>
