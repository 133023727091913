import { NgClass } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, DestroyRef, OnDestroy, Signal, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Breadcrumb, BreadcrumbComponent } from '@my-tomorrows/components';
import { FooterComponent, NavbarComponent, NavbarScrollDirective } from '@my-tomorrows/core-ui';
import { AnalyticsService, PostHogEvents, RouteData, SharedAuthService } from '@my-tomorrows/shared-data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { SessionManagerService } from './services/session-manager/session-manager.service';

@Component({
  standalone: true,
  imports: [
    HttpClientModule,
    RouterModule,
    TranslateModule,
    FooterComponent,
    BreadcrumbComponent,
    RouterOutlet,
    NgClass,
    NavbarComponent,
    NavbarScrollDirective,
  ],
  selector: 'myt-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [SessionManagerService],
})
export class AppComponent implements OnDestroy {
  private authService = inject(SharedAuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private titleService = inject(Title);
  private translateService = inject(TranslateService);
  private sessionManagerService = inject(SessionManagerService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly msalService = inject(MsalService);
  private readonly destroyRef = inject(DestroyRef);

  readonly $routeData = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((): Data => {
        const routeSnapshot = this.route.root.snapshot;
        const routeData = this.getRouteData(routeSnapshot);
        this.setPageTitle(routeData);
        this.analyticsService.posthogCapture(PostHogEvents.PageView);
        return routeData;
      }),
    ),
  );
  readonly $breadcrumbData: Signal<Breadcrumb[]> = computed(() => this.$routeData()?.[RouteData.Breadcrumb]);
  readonly $hideHeader: Signal<boolean> = computed(() => this.$routeData()?.[RouteData.HideHeader]);
  readonly $fullScreenHeight: Signal<boolean> = computed(() => this.$routeData()?.[RouteData.FullScreenHeight]);

  private getRouteData(snapshot: ActivatedRouteSnapshot): Data {
    let data = { ...snapshot.data };
    if (snapshot.firstChild) {
      data = { ...data, ...this.getRouteData(snapshot.firstChild) };
    }
    return data;
  }

  constructor() {
    this._subscribeToMsalEvents();
  }

  ngOnDestroy(): void {
    this.sessionManagerService.initializer.destroy();
  }

  private setPageTitle(routeData: Data): void {
    const tabTitleFromRoute = routeData[RouteData.TabTitle];
    const tabTitle = this.translateService.instant(tabTitleFromRoute || 'PLATFORM.DEFAULT_TAB_TITLE');
    this.titleService.setTitle(tabTitle);
  }

  private _subscribeToMsalEvents(): void {
    this.msalBroadcastService.msalSubject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: EventMessage) => {
      if (
        event?.eventType === EventType.LOGOUT_SUCCESS ||
        event?.eventType === EventType.SSO_SILENT_FAILURE ||
        event?.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        event?.eventType === EventType.LOGIN_FAILURE
      ) {
        this.msalService.instance.setActiveAccount(null);
        this.authService.setDefaultUser();
      }
    });
  }
}
