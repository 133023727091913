import { Environment } from '@my-tomorrows/core-environment';

export const environment: Environment = {
  production: false,
  searchApiUrl: 'https://enterprise-search-develop.mytomorrows.com/v01',
  microservicesApiUrl: 'https://microservice-develop.mytomorrows.com/v1.3.0',
  searchGraphQlUrl: 'https://enterprise-search-develop.mytomorrows.com/gql/graphql',
  googleMapsApiKey: 'AIzaSyDNvxXOBUkS8jU3YOQLM3MB_kAyRO76bgE',
  cometChatAppId: '2547332a47db96ea',
  oldHcpPortalLink: 'https://main-portal-release.mytomorrows.com/hcp-portal/en/signup?contact=created',
  msal: {
    clientId: 'e701aafb-2017-46eb-baf5-1692dfcd3d38',
    authority: 'https://login.microsoftonline.com/bed0d610-d02a-4ca3-b237-f79065788b7b',
    redirectUri: 'https://platform-qa.mytomorrows.com/internal-login',
  },
  posthogKey: 'phc_57TinGonGjc9ELNri0uWp28jWlrkLEW8ik2WSQbay1d',
  datadogClientToken: 'pub3fdb99e6fcd03fabeae46d013b3e9b1a',
  datadogApplicationId: '0566ce20-a54c-4507-941f-99348547892d',
};
