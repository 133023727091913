import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injector, inject, runInInjectionContext } from '@angular/core';
import { SnackbarService } from '@my-tomorrows/components';
import { CONTACT_US_LINK, OTP_EXPIRED_FLAG, TOKEN_EXPIRED_FLAG } from '@my-tomorrows/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

interface ExceptionItem {
  url: RegExp;
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT';
  description?: string;
}

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const snackbarService = inject(SnackbarService);
  const injector = inject(Injector);

  const exceptions: ExceptionItem[] = [
    {
      url: /account\/me/,
      method: 'GET',
    },
    {
      url: /referral-request\/.*\/messages/,
      method: 'POST',
      description: 'Sending chat message in the referral flow, it is handled with a custom ui in chat box.',
    },
  ];

  const FlagExceptions: string[] = [OTP_EXPIRED_FLAG, TOKEN_EXPIRED_FLAG];

  return next(req).pipe(
    tap({
      error: (error) => {
        const isException = exceptions.some((i) => i.url.test(req.url) && (i.method ? req.method === i.method : true));

        if (!isException && !(FlagExceptions.includes(error.error.error_type) || FlagExceptions.includes(error.error.details))) {
          runInInjectionContext(injector, () => {
            const translateService = inject(TranslateService);
            const content = translateService.instant('PLATFORM.SNACKBAR_ERROR_MESSAGE', { contactUsLink: CONTACT_US_LINK });
            snackbarService.openErrorSnackbar(content);
          });
        }
      },
    }),
  );
};
