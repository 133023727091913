import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable } from 'rxjs';
import { MicrosoftValidationResponseApi } from './models/responses/microsoft-validate.interface';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  private readonly httpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly url = this.environment.microservicesApiUrl;

  validateMicrosoftToken(token: string): Observable<MicrosoftValidationResponseApi> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.httpClient.get<MicrosoftValidationResponseApi>(`${this.url}/auth/microsoft`, { headers, withCredentials: true });
  }
}
