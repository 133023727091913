import { datadogRum } from '@datadog/browser-rum';
import { environment } from './environment/environment';

export function initializeDatadog(): void {
  if (!environment.datadogClientToken || !environment.datadogApplicationId) {
    return;
  }

  datadogRum.init({
    clientToken: environment.datadogClientToken,
    applicationId: environment.datadogApplicationId,
    site: 'datadoghq.eu',
    service: 'platform-web',
    env: environment.production ? 'production' : 'develop',
    // Enable after CPT-1826
    // allowedTracingUrls: [environment.microservicesApiUrl, environment.searchGraphQlUrl, environment.searchApiUrl],
    allowedTracingUrls: [],
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: 'mask',
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
}
