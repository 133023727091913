import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { COOKIE_STATEMENT_LINK, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '@my-tomorrows/shared-data';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'myt-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, RouterModule],
})
export class FooterComponent {
  readonly termsOfServiceLink = TERMS_OF_SERVICE_LINK;
  readonly privacyPolicyLink = PRIVACY_POLICY_LINK;
  readonly cookieStatementLink = COOKIE_STATEMENT_LINK;

  readonly year = new Date().getFullYear();
}
