<div class="block h-full w-full bg-white">
  <section class="flex h-full">
    <div class="m-auto max-w-screen-sm text-center">
      <myt-icon name="pill" class="text-primary mb-3 flex justify-center" />
      <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl dark:text-white">Page not found</p>
      <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we couldn’t find the page you’re looking for.</p>
      <myt-button [type]="buttonType.Basic" (buttonClick)="router.navigate(['/home'])"
        ><span class="text-primary">{{ 'PLATFORM.NOT_FOUND.BUTTON' | translate }}</span></myt-button
      >
    </div>
  </section>
</div>
