import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ButtonType, ButtonsComponent, IconsComponent } from '@my-tomorrows/components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'myt-not-found',
  standalone: true,
  imports: [RouterLink, ButtonsComponent, TranslateModule, IconsComponent],
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  readonly router = inject(Router);
  readonly buttonType = ButtonType;
}
